import React from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Paper,
    Divider,
    Grid,
    IconButton
} from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate();

    // Navigate back to home
    const handleBackToHome = () => {
        navigate('/home');
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4" component="h1" color="primary">
                        Dashboard
                    </Typography>
                    <IconButton color="primary" onClick={handleBackToHome}>
                        <HomeIcon />
                    </IconButton>
                </Box>
                <Divider sx={{ my: 2 }} />

                <Typography variant="h6" gutterBottom>
                    Welcome to your POS Dashboard
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                    Here you can manage your sales, view reports, and more.
                </Typography>

                {/* Dashboard Cards Section */}
                <Grid container spacing={3}>
                    {/* Sales Management */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <ConstructionIcon fontSize="large" color="secondary" />
                                <Typography variant="h6" sx={{ mt: 1 }}>
                                    Manage Sales
                                </Typography>
                                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                                    Go to Sales
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>

                    {/* View Reports */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <ConstructionIcon fontSize="large" color="secondary" />
                                <Typography variant="h6" sx={{ mt: 1 }}>
                                    View Reports
                                </Typography>
                                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                                    Go to Reports
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Manage Inventory */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <ConstructionIcon fontSize="large" color="secondary" />
                                <Typography variant="h6" sx={{ mt: 1 }}>
                                    Manage Inventory
                                </Typography>
                                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                                    Go to Inventory
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default Dashboard;
