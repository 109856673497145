import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
    Typography,
    TablePagination,
    TableContainer,
} from '@mui/material';
import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import axios from 'axios';
import config from '../../services/config';
import InvoiceDetail from './InvoiceDetail';
import log from 'loglevel';

const InvoiceSearch = ({ open, onClose, branchId }) => {
    const [searchInput, setSearchInput] = useState('');
    const [allInvoices, setAllInvoices] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);

    // Pagination states
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Default rows per page
    log.setLevel('info');

    useEffect(() => {
        const fetchInvoices = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/invoice/getallinvoice`, { params: { branchId } });
                setAllInvoices(response.data);
                setFilteredInvoices(response.data);
            } catch (error) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error fetching Invoices:', error);
                }
                log.error('Error fetching Invoices:', error);
            }
        };

        if (open) {
            fetchInvoices();
        }
    }, [open, branchId]);

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchInput(value);
        const filtered = allInvoices.filter(invoice =>
            invoice.invoiceNo.toLowerCase().includes(value.toLowerCase()) ||
            invoice.customerName.toLowerCase().includes(value.toLowerCase()) ||
            invoice.date.includes(value.toLowerCase())
        );
        setFilteredInvoices(filtered);
        setPage(0); // Reset to the first page whenever the search input changes
    };

    const handleDetailOpen = (invoice) => {
        setSelectedInvoice(invoice);
        setDetailDialogOpen(true);
    };

    const handleDetailClose = () => {
        setDetailDialogOpen(false);
        setSelectedInvoice(null);
    };

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page
    };

    // Calculate current invoices for the current page
    const currentInvoices = filteredInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                <Typography variant="h6">Search Invoice</Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ mt: 2, mb: 2 }}
                    label="Search by Invoice No / Customer"
                    variant="outlined"
                    fullWidth
                    value={searchInput}
                    onChange={handleSearchInputChange}
                />
                <Paper elevation={2} sx={{ borderRadius: 2 }}>
                    <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Invoice No</strong></TableCell>
                                    <TableCell><strong>Date</strong></TableCell>
                                    <TableCell><strong>Customer</strong></TableCell>
                                    <TableCell align="right"><strong>Sub Total</strong></TableCell>
                                    <TableCell align="right"><strong>Discount</strong></TableCell>
                                    <TableCell align="right"><strong>Excess Funds</strong></TableCell>
                                    <TableCell align="right"><strong>Grand Total</strong></TableCell>
                                    <TableCell align="center"><strong>Actions</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentInvoices.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">
                                            <Typography variant="body2" color="textSecondary">No data available</Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    currentInvoices.map((invoice) => (
                                        <TableRow key={invoice.invoiceNo}>
                                            <TableCell>{invoice.invoiceNo}</TableCell>
                                            <TableCell>{invoice.date}</TableCell>
                                            <TableCell>{invoice.customerName}</TableCell>
                                            <TableCell align='right'>{parseFloat(invoice.subTotal).toFixed(2)}</TableCell>
                                            <TableCell align='right'>{parseFloat(invoice.discount).toFixed(2)}</TableCell>
                                            <TableCell align='right'>{parseFloat(invoice.excessFunds).toFixed(2)}</TableCell>
                                            <TableCell align='right'>{parseFloat(invoice.grandTotal).toFixed(2)}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="View Items" arrow>
                                                    <IconButton onClick={() => handleDetailOpen(invoice)} aria-label="view items">
                                                        <ShoppingCartIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredInvoices.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>

            {/* InvoiceDetail Dialog */}
            <InvoiceDetail
                open={detailDialogOpen}
                onClose={handleDetailClose}
                invoice={selectedInvoice}
                branchId={branchId}
            />
        </Dialog>
    );
};

export default InvoiceSearch;
