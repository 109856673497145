import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const checkuserbranchnull = (WrappedComponent) => {
    return (props) => {
        const navigate = useNavigate();
        const location = useLocation();

        const { user, branchId } = location.state || {};

        useEffect(() => {

            if (!branchId || !user) {
                navigate('/'); // Redirect to the login page
            }
        }, [branchId, user, navigate, props]); // Added props to the dependencies

        return <WrappedComponent {...props} />;
    };
};

export default checkuserbranchnull;
