import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Container,
    Grid,
    TextField,
    Button,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    AppBar,
    Toolbar,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CardContent,
    Card,
    TableContainer,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const TransferNew = ({ user, branchId }) => {
    const [branches, setBranches] = useState([]);
    const [items, setItems] = useState([]);
    const [transferItems, setTransferItems] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemDetails, setItemDetails] = useState({
        branchStockId: '',
        itemBatchId: '',
        qty: 0,
        buyingPrice: 0.00,
        sellingPrice: 0.00,
        unit: '',
        availableQty: 0,
    });
    const [itemBatches, setItemBatches] = useState([]);
    const [batchDialogOpen, setBatchDialogOpen] = useState(false);
    const [selectedBatchIndex, setSelectedBatchIndex] = useState(0);
    log.setLevel('info');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    const branchRef = useRef(null);
    const itemRef = useRef(null);
    const qtyRef = useRef(null);

    const fetchData = useCallback(async () => {
        try {
            const branchesResponse = await axios.get(`${config.API_BASE_URL}/branch/getbranches`);
            const filteredBranches = branchesResponse.data.filter(branch => branch.branchId !== user[0].Branch_Id);
            setBranches(filteredBranches);

            const itemsResponse = await axios.get(`${config.API_BASE_URL}/stock/getbranchitemswithprice`, { params: { branchId } });
            const groupedItems = {};
            itemsResponse.data.forEach(item => {
                const key = `${item.itemCode} - ${item.itemName}`;
                if (!groupedItems[key]) {
                    groupedItems[key] = [];
                }
                groupedItems[key].push(item);
            });
            setItems(Object.entries(groupedItems).map(([label, batches]) => ({ label, batches })));
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching data:', error);
            }
            log.error('Error fetching data:', error);
        }
    }, [user, branchId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleItemSelect = (event, value) => {
        if (value) {
            const selected = value.batches;
            setSelectedItem(value);

            if (selected.length === 1) {
                const batch = selected[0];
                setItemDetails({
                    branchStockId: batch.branchStockId,
                    itemBatchId: batch.itemBatchId,
                    qty: 0,
                    itemName: batch.itemName,
                    itemCode: batch.itemCode,
                    buyingPrice: parseFloat(batch.buyingPrice),
                    sellingPrice: parseFloat(batch.sellingPrice),
                    unit: batch.unit,
                    availableQty: batch.availableQty,
                });
                if (qtyRef.current) {
                    qtyRef.current.focus();
                }
            } else {
                setItemBatches(selected);
                setBatchDialogOpen(true);
                setSelectedBatchIndex(0);
            }
        } else {
            setSelectedItem(null);
            clearFields();
        }
    };

    const handleBatchSelect = (batch) => {
        setItemDetails({
            branchStockId: batch.branchStockId,
            itemBatchId: batch.itemBatchId,
            qty: 0,
            itemName: batch.itemName,
            itemCode: batch.itemCode,
            buyingPrice: parseFloat(batch.buyingPrice),
            sellingPrice: parseFloat(batch.sellingPrice),
            unit: batch.unit,
            availableQty: batch.availableQty,
        });
        setBatchDialogOpen(false);
        setTimeout(() => {
            if (qtyRef.current) {
                qtyRef.current.focus();
            }
        }, 0); // Use setTimeout to ensure the DOM is updated before focusing
    };

    const handleBatchKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            setSelectedBatchIndex((prevIndex) => Math.min(prevIndex + 1, itemBatches.length - 1));
        } else if (event.key === 'ArrowUp') {
            setSelectedBatchIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (event.key === 'Enter') {
            handleBatchSelect(itemBatches[selectedBatchIndex]);
        } else if (event.key === 'Escape') {
            setBatchDialogOpen(false);
        }
    };

    const handleBranchSelect = (event) => {
        const branchId = event.target.value;
        const selected = branches.find(branch => branch.branchId === branchId);
        setSelectedBranch(selected || null);
    };

    const handleInputChange = (field, value) => {
        if (field === 'qty') {
            setItemDetails(prev => ({
                ...prev,
                qty: value === '' ? '' : Number(value), // Allow empty string, else convert to a number
            }));
        }
    };

    const handleQtyBlur = () => {
        if (itemDetails.qty === '' || itemDetails.qty === null) {
            setItemDetails(prev => ({ ...prev, qty: 0 }));
        }
    };

    const handleQtyFocus = (event) => {
        event.target.select();
    };

    const handleQtyKeyPress = (event) => {
        if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const addItemToTable = () => {

        if (!selectedItem) {
            setAlertMessage('Please select an Item.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemRef.current.focus();
            return;
        }
        if (itemDetails.qty <= 0) {
            setAlertMessage('Please enter a quantity greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            qtyRef.current.focus();
            return;
        }
        if (itemDetails.qty > itemDetails.availableQty) {
            setAlertMessage(`Please ensure the quantity does not exceed the available quantity (${itemDetails.availableQty}).`);
            setAlertSeverity('error');
            setAlertOpen(true);
            qtyRef.current.focus();
            return;
        }

        const exists = transferItems.some(item => item.itemBatchId === itemDetails.itemBatchId);
        if (exists) {
            setAlertMessage('This item has already been added to the table.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemRef.current.focus();
            return;
        }

        setTransferItems(prevItems => [
            ...prevItems,
            {
                branchStockId: itemDetails.branchStockId,
                itemBatchId: itemDetails.itemBatchId,
                itemCode: itemDetails.itemCode,
                itemName: itemDetails.itemName,
                qty: itemDetails.qty,
                buyingPrice: itemDetails.buyingPrice,
                sellingPrice: itemDetails.sellingPrice,
            },
        ]);
        clearFields();
        if (itemRef.current) {
            itemRef.current.focus();
        }
    };

    const totalQuantity = transferItems.reduce((acc, item) => acc + item.qty, 0);

    const clearFields = () => {
        setItemDetails({
            branchStockId: '',
            itemBatchId: '',
            qty: 0,
            buyingPrice: 0.00,
            sellingPrice: 0.00,
            unit: '',
            availableQty: 0,
        });
        setSelectedItem(null);
    };

    const clearAll = () => {
        setConfirmOpen(true);
        setConfirmType("C");
    };

    const confirmClearAll = () => {
        clearFields();
        setSelectedBranch(null);
        setTransferItems([]);
        setConfirmOpen(false);
    };

    const removeItem = (index) => {
        setTransferItems(transferItems.filter((_, i) => i !== index));
    };

    const saveTransfer = async () => {

        if (!selectedBranch) {
            setAlertMessage('Please select a Branch.');
            setAlertSeverity('error');
            setAlertOpen(true);
            branchRef.current.focus();
            return;
        }
        if (transferItems.length === 0) {
            setAlertMessage('Please add at least one item to the transfer before saving.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("S");
    };

    const saveConfirmTransfer = async () => {

        try {
            const transferData = {
                fromBranchId: branchId,
                toBranchId: selectedBranch.branchId,
                items: transferItems,
                totalQty: totalQuantity,
                sysUser: user[0].User_Name,
            };

            const response = await axios.post(`${config.API_BASE_URL}/transfer/savestocktransfer`, { data: transferData });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                confirmClearAll();
                await fetchData();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving transfer:', error);
            }
            log.error('Error saving transfer:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    return (
        <Container>
            <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                <Toolbar>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        Stock Transfer
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" required>
                        <InputLabel>Transfer To</InputLabel>
                        <Select
                            value={selectedBranch ? selectedBranch.branchId : ''}
                            onChange={handleBranchSelect}
                            inputRef={branchRef}
                        >
                            {branches.map((branch) => (
                                <MenuItem key={branch.branchId} value={branch.branchId}>
                                    {branch.branchName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={items}
                        getOptionLabel={(item) => item.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Item Code / Name"
                                variant="outlined"
                                fullWidth
                                required
                                inputRef={itemRef}
                            />
                        )}
                        value={selectedItem}
                        onChange={handleItemSelect}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Quantity"
                        type="text"
                        value={itemDetails.qty}
                        onChange={(e) => handleInputChange('qty', e.target.value)}
                        onBlur={handleQtyBlur}
                        onFocus={handleQtyFocus}
                        onKeyPress={handleQtyKeyPress}
                        fullWidth
                        required
                        inputRef={qtyRef}
                        inputProps={{
                            min: 0,
                            step: 1,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Unit"
                        type="text"
                        value={itemDetails.unit}
                        disabled
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Available Qty"
                        type="number"
                        value={itemDetails.availableQty}
                        disabled
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Buying Price"
                        type="text"
                        value={formatCurrency(itemDetails.buyingPrice)}
                        disabled
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Selling Price"
                        type="text"
                        value={formatCurrency(itemDetails.sellingPrice)}
                        disabled
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Button variant="contained" color="primary" onClick={addItemToTable}>
                        Add Item
                    </Button>
                </Grid>
            </Grid>

            <Paper sx={{ mt: 3 }}>
                <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Code</TableCell>
                                <TableCell>Item Name</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Buying Price</TableCell>
                                <TableCell>Selling Price</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transferItems.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                transferItems.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.itemCode}</TableCell>
                                        <TableCell>{item.itemName}</TableCell>
                                        <TableCell>{item.qty}</TableCell>
                                        <TableCell align="right">{formatCurrency(item.buyingPrice)}</TableCell>
                                        <TableCell align="right">{formatCurrency(item.sellingPrice)}</TableCell>
                                        <TableCell>
                                            <Button color="secondary" onClick={() => removeItem(index)}>
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Typography variant="h6" sx={{ mt: 2 }}>
                Total Quantity: {totalQuantity}
            </Typography>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outlined" color="primary" onClick={clearAll}>
                    Clear
                </Button>
                <Button variant="contained" color="primary" onClick={saveTransfer}>
                    Save Transfer
                </Button>
            </Box>

            <Dialog open={batchDialogOpen} onClose={() => setBatchDialogOpen(false)} onKeyDown={handleBatchKeyDown}>
                <DialogTitle>Select a Batch</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {itemBatches.map((batch, index) => (
                            <Card
                                key={index}
                                sx={{
                                    cursor: 'pointer',
                                    border: selectedBatchIndex === index ? '2px solid #1976d2' : '1px solid lightgrey',
                                    transition: 'border 0.3s',
                                }}
                                onClick={() => handleBatchSelect(batch)}
                                onMouseEnter={() => setSelectedBatchIndex(index)}
                            >
                                <CardContent>
                                    <Typography variant="h6">{batch.itemName}</Typography>
                                    <Typography variant="body2">Available Qty: {batch.availableQty}</Typography>
                                    <Typography variant="body2">Unit: {batch.unit}</Typography>
                                    <Typography variant="body2">Buying Price: {formatCurrency(batch.buyingPrice)}</Typography>
                                    <Typography variant="body2">Selling Price: {formatCurrency(batch.sellingPrice)}</Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBatchDialogOpen(false)} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Custom Alert */}
            <CustomAlert
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                message={alertMessage}
                severity={alertSeverity}
            />

            {/* Confirmation Alert */}
            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'S' ? "Do you want to save this transfer?" :
                    confirmType === 'C' ? "Do you want to clear this transfer?" : ""}
                severity="warning"
                onConfirm={confirmType === 'S' ? saveConfirmTransfer :
                    confirmType === 'C' ? confirmClearAll : null}
                isConfirmation
            />

        </Container>
    );
};

export default TransferNew;
