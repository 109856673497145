import React from 'react';
import ReactDOM from 'react-dom/client';
import BillPrint from '../Invoice/BillPrint';
import SaleReport from '../Report/SaleReport';

export const printInvoice = (iframeRef, invoiceData, items, isCopy) => {
    const printWindow = iframeRef.current.contentWindow || iframeRef.current;

    const styles = `
        @page {
    size: 3in auto;
    margin: 0;
}

.bill-print {
    width: 3in;
    padding: 0 10px 10px 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*border: solid; /* Adjusted border property */
    /*border-color: #000000; /* Set border color */
    position: relative;
    margin: 0;
}

.header {
    text-align: center;
    margin-bottom: 4px;
}

.logo {
    margin: 4px;
    width: 100px;
    height: auto;
}

.address,
.contact {
    font-size: 12px;
    font-weight: bold;
}

.invoice-info {
    text-align: left;
    width: 100%;
}

.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.item-table {
    width: 100%;
    border-collapse: collapse;
}

.item-table th,
.item-table td {
    font-size: 10px;
    padding-top: 4px;
    padding-right: 4px;
    padding-bottom: 1px;
    padding-left: 4px;
}

.item-table th {
    background-color: #f0f0f0;
}

.item-table th+td {
    border-top: 2px solid black;
}

.summary {
    width: 100%;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.summary-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 4px;
}

.label {
    padding-left: 120px;
}

.footer {
    text-align: center;
    margin-bottom: 4px;
    font-size: 8px;
}

hr {
    border: 1px solid black;
    width: 100%;
}

@media print {
    .bill-print {
        margin: 0;
        padding: 0 10px 10px 10px;
        font-size: 10px;
        page-break-inside: avoid;
    }
}

.copy-label {
    position: absolute;
    top: 10px;
    right: 8px;
    font-weight: bold;
    font-size: 12px;
    color: black;
    text-align: right;
    width: auto;
    border: #000000, solid, 2px;
    padding: 3px;
}

/*@media print {
    body {
        margin: 0;
    }

    .bill-print {
        margin: 0;
        padding: 10px;
        font-size: 10px;
        page-break-inside: avoid;
    }
}*/
        `;

    // Write content to iframe
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Invoice</title>');
    printWindow.document.write(`<style>${styles}</style>`);
    printWindow.document.write('</head><body>');

    // Create a div for the BillPrint component
    const billPrintElement = document.createElement('div');

    // Get the root container of the iframe's document
    const root = ReactDOM.createRoot(billPrintElement);

    // Render the BillPrint component into the div using createRoot
    root.render(<BillPrint invoiceData={invoiceData} items={items} isCopy={isCopy} />);

    // Append the generated BillPrint content to the iframe's body
    printWindow.document.body.appendChild(billPrintElement);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    // Wait for the iframe to load the content before printing
    printWindow.onload = () => {
        setTimeout(() => {
            printWindow.focus(); // Ensure the print window is focused
            printWindow.print(); // Open the print dialog
            printWindow.onafterprint = () => {
                printWindow.close();
            };
        }, 250); // Adjust the delay as necessary
    };

};

export const printSalesReport = (iframeRef, branchId, records, columns, branchName, fromDate, toDate) => {
    const printWindow = iframeRef.current.contentWindow || iframeRef.current;

    const styles = `
        @page {
    size: A4; /* Set the page size to A4 */
    margin: 20px 20px 20px 36px; /* Apply your required margins */
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Push the footer to the bottom */
    min-height: calc(100vh - 40px); /* Ensure the container spans the full height minus top & bottom margins */
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    margin: 0;
    page-break-inside: avoid; /* Prevent breaking this container across pages */
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    margin-bottom: 4px;
}

.logo {
    position: absolute;
    left: 10px; /* Adjust padding as needed */
    top: 10px;   /* Adjust padding as needed */
    margin: 4px;
    width: 162px;
    height: 65px;
}

.branchname {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 2px; 
}

.address,
.contact {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 2px;
}

.branchname {
    margin-top: 10px; /* Add some spacing above the branch name if needed */
}

.content {
    flex: 1; /* Ensure content fills available space */
    max-height: calc(100vh - 80px); /* Subtract 40px for top and bottom margins + footer height */
    overflow: hidden; /* Prevent content overflow */
}

.report-header {
    text-align: center;
    width: 100%;
}

.title {
    font-size: 24px;
    font-weight: bold;
    align-items: center;
    margin-top: 15px;
    text-decoration: underline;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    font-size: 11px;
    font-weight: bold;
}

.table-container {
    margin: 10px auto;
    overflow-x: auto; /* Add horizontal scrolling for small screens */
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 11px;
    margin: 0 auto;
}

.data-table thead th {
    background-color: #f0f0f0;
    font-weight: bold;
    padding: 8px;
    text-align: left;
    border-top: 1px solid #000000; /* Border only on top */
    border-bottom: 1px solid #000000; /* Border only on bottom */
}

.data-table tbody td {
    padding: 8px;
    // border: 1px solid #ddd;
    // text-align: left;
}

.data-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Zebra stripe effect */
}

.data-table tbody tr:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
}

.data-table tfoot td {
    background-color: #f0f0f0; /* Light gray background for the footer */
    padding: 8px;
    border-top: 1px solid #000; /* Emphasize the separation from the table body */
    text-align: right;
}

.footer {
    text-align: center;
    margin-top: auto; /* Push footer to the bottom */
    font-size: 11px;
    page-break-inside: avoid; /* Prevent the footer from breaking across pages */
    page-break-before: avoid; /* Ensure the footer starts on the same page */
    height: 30px; /* Reserve fixed space for the footer */
}

hr {
    border: 0; /* Remove default border */
    border-top: 1px solid #000000;
    width: 100%;
}

@media print {
    .page {
        min-height: calc(100vh - 40px); /* Fill the page minus margins during printing */
    }

    .footer {
        position: relative;
        bottom: 0; /* Ensure footer stays at the bottom */
        page-break-inside: avoid;
        page-break-before: avoid;
    }
}
        `;

    // Write content to iframe
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Invoice</title>');
    printWindow.document.write(`<style>${styles}</style>`);
    printWindow.document.write('</head><body>');

    // Create a div for the BillPrint component
    const billPrintElement = document.createElement('div');

    // Get the root container of the iframe's document
    const root = ReactDOM.createRoot(billPrintElement);

    // Render the BillPrint component into the div using createRoot
    root.render(<SaleReport branchId={branchId} records={records} columns={columns} branchName={branchName} fromDate={fromDate} toDate={toDate} />);

    // Append the generated BillPrint content to the iframe's body
    printWindow.document.body.appendChild(billPrintElement);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    // Wait for the iframe to load the content before printing
    printWindow.onload = () => {
        setTimeout(() => {
            printWindow.focus(); // Ensure the print window is focused
            printWindow.print(); // Open the print dialog
            printWindow.onafterprint = () => {
                printWindow.close();
            };
        }, 250); // Adjust the delay as necessary
    };
};
