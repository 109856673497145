import React, { useEffect, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';

const severityStyles = {
    error: {
        title: 'Error',
        color: '#d32f2f', // Dark Red
        icon: <ErrorIcon fontSize="large" sx={{ color: '#d32f2f' }} />,
    },
    success: {
        title: 'Success',
        color: '#388e3c', // Dark Green
        icon: <CheckCircleIcon fontSize="large" sx={{ color: '#388e3c' }} />,
    },
    warning: {
        title: 'Warning',
        color: '#ed6c02', // Dark Orange
        icon: <WarningAmberIcon fontSize="large" sx={{ color: '#ed6c02' }} />,
    },
    info: {
        title: 'Info',
        color: '#1976d2', // Dark Blue
        icon: <InfoIcon fontSize="large" sx={{ color: '#1976d2' }} />,
    },
};

const CustomAlert = ({ open, onClose, message, severity, onConfirm, isConfirmation }) => {
    const { title, color, icon } = severityStyles[severity] || severityStyles.info; // Default to info

    const okButtonRef = useRef(null);

    useEffect(() => {
        if (open && severity === 'error' && okButtonRef.current) {
            okButtonRef.current.focus(); // Focus the OK button if it exists
        }
    }, [open, severity]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth onKeyDown={handleKeyDown}>
            <DialogTitle sx={{ bgcolor: '#f5f5f5', color: color, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 1 }}>{icon}</Box>
                <Typography variant="h6" noWrap>{title}</Typography>
            </DialogTitle>
            <DialogContent sx={{ paddingY: 2 }}>
                <Typography variant="body2" color="text.secondary">{message}</Typography>
            </DialogContent>
            <DialogActions sx={{ paddingY: 1 }}>
                {isConfirmation ? (
                    <>
                        <Button onClick={onClose} color="inherit" variant="outlined">
                            Cancel
                        </Button>
                        <Button ref={okButtonRef} onClick={onConfirm} color="primary" variant="contained" autoFocus>
                            Confirm
                        </Button>
                    </>
                ) : (
                    <Button ref={okButtonRef} onClick={onClose} color="primary" variant="contained" autoFocus>
                        OK
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CustomAlert;
