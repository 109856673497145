import React, { useState, useRef, useEffect } from 'react';
import { Button, Grid, Typography, TextField, AppBar, Toolbar, Container, Paper, TableContainer, Table, TableHead, TableRow, TableCell, MenuItem, Select, FormControl, InputLabel, Box, TableBody, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';

function Return({ user, branchId }) {
    const [invoiceNo, setInvoiceNo] = useState('');
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [returnMethod, setReturnMethod] = useState('');
    const [returnItems, setReturnItems] = useState([]);
    const [totalQty, setTotalQty] = useState('0');
    const [totalAmount, setTotalAmount] = useState('0.00');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [goodQty, setGoodQty] = useState(0);
    const [badQty, setBadQty] = useState(0);

    const invoiceNoRef = useRef(null);
    const returnMethodRef = useRef(null);

    log.setLevel('info');

    const searchInvoice = async () => {
        try {

            confirmClearAllWithoutInvoiceNo();

            if (!invoiceNo) {
                setAlertMessage('Please enter the Invoice No.');
                setAlertSeverity('error');
                setAlertOpen(true);
                invoiceNoRef.current.focus();
                return;
            }

            const response = await axios.get(`${config.API_BASE_URL}/invoice/getinvoicedata`, { params: { invoiceNo, branchId } });
            setInvoiceData(response.data.invoice[0]);
            setInvoiceItems(response.data.items);

        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const saveReturn = async () => {

        if (!invoiceNo) {
            setAlertMessage('Please enter the Invoice No.');
            setAlertSeverity('error');
            setAlertOpen(true);
            invoiceNoRef.current.focus();
            return;
        }
        if (returnItems.length === 0) {
            setAlertMessage('Please add at least one item to the return before saving.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }
        if (!returnMethod) {
            setAlertMessage('Please select a return method.');
            setAlertSeverity('error');
            setAlertOpen(true);
            returnMethodRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("S");
    };

    const saveConfirmReturn = async () => {

        const totalFinalAmount = Number(totalAmount) === Number(invoiceData.subTotal) ? invoiceData.grandTotal : totalAmount;

        try {
            const retunrData = {
                invoiceNo,
                returnItems,
                returnMethod,
                totalQty,
                totalFinalAmount: totalFinalAmount,
                sysUser: user[0].User_Name,
                branchId,
            };

            const response = await axios.post(`${config.API_BASE_URL}/return/savereturn`, { retunrData: retunrData });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                confirmClearAll();
            }

        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };
    const clearAll = () => {
        setConfirmOpen(true);
        setConfirmType("C");
    };
    const confirmClearAllWithoutInvoiceNo = () => {
        setInvoiceData({});
        setInvoiceItems([]);
        setReturnMethod('');
        setReturnItems([]);
        setTotalQty('0');
        setTotalAmount('0.00');
    };
    const confirmClearAll = () => {
        setInvoiceNo('');
        confirmClearAllWithoutInvoiceNo();
        setConfirmOpen(false);
    };

    const openReturnDialog = (item) => {
        setSelectedItem(item);
        setGoodQty(0);
        setBadQty(0);
        setDialogOpen(true);
    };

    const closeReturnDialog = () => {
        setDialogOpen(false);
    };

    const handleGoodQtyChange = (event) => {
        const value = Math.max(0, Number(event.target.value) || 0);
        setGoodQty(value);
    };

    const handleBadQtyChange = (event) => {
        const value = Math.max(0, Number(event.target.value) || 0);
        setBadQty(value);
    };

    const addReturnItem = () => {
        const totalReturnQty = goodQty + badQty;

        if (totalReturnQty === 0) {
            setAlertMessage('Total quantity (Good + Bad) cannot be zero.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }
        if (totalReturnQty > selectedItem.qty) {
            setAlertMessage('Total quantity cannot be greater than the available quantity in the invoice.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        const existingItem = returnItems.find(item => item.itemCode === selectedItem.itemCode);
        if (existingItem) {
            setAlertMessage('This item has already been added to the return.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        const returnItem = {
            itemCode: selectedItem.itemCode,
            itemName: selectedItem.itemName,
            unit: selectedItem.unit,
            goodQty: goodQty,
            badQty: badQty,
            totalQty: totalReturnQty,
            buyingPrice: selectedItem.buyingPrice,
            sellingPrice: selectedItem.sellingPrice,
            total: (selectedItem.sellingPrice * totalReturnQty).toFixed(2),
            discount: selectedItem.itemDiscount,
            subTotal: (selectedItem.sellingPrice * totalReturnQty - (selectedItem.itemDiscount * totalReturnQty)).toFixed(2),
        };

        setReturnItems([...returnItems, returnItem]);

        setTotalQty((prevTotalQty) => (parseInt(prevTotalQty) + totalReturnQty).toString());
        setTotalAmount((prevTotalAmount) => (parseFloat(prevTotalAmount) + parseFloat(returnItem.subTotal)).toFixed(2));

        setGoodQty(0);
        setBadQty(0);
        closeReturnDialog();

    };

    const removeItem = (index) => {
        const updatedReturnItems = returnItems.filter((_, i) => i !== index);

        const newTotalQty = updatedReturnItems.reduce((sum, item) => sum + item.totalQty, 0);
        const newTotalAmount = updatedReturnItems.reduce((sum, item) => sum + parseFloat(item.subTotal), 0).toFixed(2);

        setReturnItems(updatedReturnItems);
        setTotalQty(newTotalQty.toString());
        setTotalAmount(newTotalAmount);
    };

    useEffect(() => {
        if (invoiceData.customerId === 1 && returnMethod !== 'Cash') {
            setReturnMethod('Cash');
        }
    }, [invoiceData.customerId, returnMethod]);

    return (
        <Container>
            <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                <Toolbar>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        Invoice Return
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label="Invoice No"
                        value={invoiceNo}
                        onChange={(e) => setInvoiceNo(e.target.value)}
                        fullWidth
                        inputRef={invoiceNoRef}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Button variant="contained" color="primary" fullWidth onClick={searchInvoice}>
                        Search
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body1"><strong>Invoice Date: </strong> {invoiceData ? invoiceData.date : ''}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body1">
                        <strong>Grand Total (Rs.): </strong>
                        {invoiceData && invoiceData.grandTotal != null ?
                            parseFloat(invoiceData.grandTotal).toFixed(2) :
                            '0.00'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body1"><strong>Customer: </strong> {invoiceData ? invoiceData.customerName : ''}</Typography>
                </Grid>
            </Grid>

            {/* Invoice Item Table */}
            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                Invoice Item(s)
            </Typography>
            <Paper sx={{ mb: 3 }}>
                <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Code</TableCell>
                                <TableCell>Item Name</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>Selling Price</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Discount</TableCell>
                                <TableCell>Sub Total</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoiceItems.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        <Typography variant="body2" color="textSecondary">No item(s) available</Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                invoiceItems.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.itemCode}</TableCell>
                                        <TableCell>{item.itemName}</TableCell>
                                        <TableCell>{item.unit}</TableCell>
                                        <TableCell align="center">{item.qty}</TableCell>
                                        <TableCell align="right">{parseFloat(item.sellingPrice).toFixed(2)}</TableCell>
                                        <TableCell align="right">{parseFloat(item.total).toFixed(2)}</TableCell>
                                        <TableCell align="right">{parseFloat(item.itemDiscount).toFixed(2)}</TableCell>
                                        <TableCell align="right">{parseFloat(item.subTotal).toFixed(2)}</TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Return item" arrow>
                                                <IconButton aria-label="Return item" onClick={() => openReturnDialog(item)}>
                                                    <UndoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* Selected Return Item Table */}
            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                Selected Return Item(s)
            </Typography>
            <Paper sx={{ mb: 3 }}>
                <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Code</TableCell>
                                <TableCell>Item Name</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Good Qty</TableCell>
                                <TableCell>Damage Qty</TableCell>
                                <TableCell>Total Qty</TableCell>
                                <TableCell>Selling Price</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Discount</TableCell>
                                <TableCell>Sub Total</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {returnItems.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={11} align="center">
                                        <Typography variant="body2" color="textSecondary">No item(s) available</Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                returnItems.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.itemCode}</TableCell>
                                        <TableCell>{item.itemName}</TableCell>
                                        <TableCell>{item.unit}</TableCell>
                                        <TableCell align="center">{item.goodQty}</TableCell>
                                        <TableCell align="center">{item.badQty}</TableCell>
                                        <TableCell align="center">{item.totalQty}</TableCell>
                                        <TableCell align="right">{parseFloat(item.sellingPrice).toFixed(2)}</TableCell>
                                        <TableCell align="right">{parseFloat(item.total).toFixed(2)}</TableCell>
                                        <TableCell align="right">{parseFloat(item.discount).toFixed(2)}</TableCell>
                                        <TableCell align="right">{parseFloat(item.subTotal).toFixed(2)}</TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Remove item" arrow>
                                                <IconButton aria-label="Remove item" onClick={() => removeItem(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Grid container alignItems="center" spacing={3} sx={{ mt: 0 }}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h6" >
                        Return Total Qty: {totalQty}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h6" >
                        Return Total Amount (Rs.): {totalAmount}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel>Return Method</InputLabel>
                        <Select
                            inputRef={returnMethodRef}
                            value={returnMethod}
                            label="Return Method"
                            onChange={(e) => {
                                const value = e.target.value;
                                setReturnMethod(value);
                            }}
                            disabled={invoiceData.customerId === 1}
                        >
                            <MenuItem value="Cash">Cash</MenuItem>
                            <MenuItem value="Credit">Credit</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Box sx={{ mt: 2, display: 'flex' }}>
                <Button variant="outlined" color="primary" onClick={clearAll}>
                    Clear
                </Button>
                <Button variant="contained" color="primary" sx={{ ml: 'auto' }} onClick={saveReturn}>
                    Save Return
                </Button>
            </Box>

            {/* Return Item Dialog */}
            <Dialog open={dialogOpen} onClose={closeReturnDialog}>
                <DialogTitle>Return Item</DialogTitle>
                <DialogContent>
                    <Typography variant="body1"><strong>Item Code:</strong> {selectedItem ? selectedItem.itemCode : ''}</Typography>
                    <Typography variant="body1"><strong>Item Name:</strong> {selectedItem ? selectedItem.itemName : ''}</Typography>
                    <Typography variant="body1"><strong>Invoice Qty:</strong> {selectedItem ? selectedItem.qty : ''}</Typography>

                    <TextField
                        label="Good Qty"
                        type="number"
                        value={goodQty}
                        onChange={handleGoodQtyChange}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        label="Bad Qty"
                        type="number"
                        value={badQty}
                        onChange={handleBadQtyChange}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        <strong>Total Qty:</strong> {goodQty + badQty}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeReturnDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={addReturnItem} color="primary">
                        Add to Return
                    </Button>
                </DialogActions>
            </Dialog>

            <CustomAlert
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                message={alertMessage}
                severity={alertSeverity}
            />

            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'S' ? "Do you want to save the Return?" :
                    confirmType === 'C' ? "Do you want to clear the Return?" : ""}
                severity="warning"
                onConfirm={confirmType === 'S' ? saveConfirmReturn :
                    confirmType === 'C' ? confirmClearAll : null}
                isConfirmation
            />

        </Container>
    );
}

export default Return;
