import React, { useEffect, useState, useRef } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, ThemeProvider, Container, AppBar, Toolbar, Typography, Grid, FormControlLabel, Checkbox,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import ReactPaginate from 'react-paginate';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const Suppier = ({ user }) => {
    const theme = useTheme();
    const [suppliers, setSuppliers] = useState([]);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 10;
    log.setLevel('info');

    // State for alerts
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    // Create refs for input fields
    const supplierNameRef = useRef(null);
    const addressRef = useRef(null);
    const emailRef = useRef(null);
    const officeNoRef = useRef(null);
    const mobileNoRef = useRef(null);

    const [formData, setFormData] = useState({
        supplierName: '',
        address: '',
        email: '',
        officeNo: '',
        mobileNo: '',
        status: true,
    });

    const [updateFormData, setUpdateFormData] = useState({
        supplierId: '',
        supplierName: '',
        address: '',
        email: '',
        officeNo: '',
        mobileNo: '',
        status: '',
    });

    useEffect(() => {
        fetchSuppliers();
    }, []);

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/supplier/getallsuppliers`);
            setSuppliers(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching suppliers:', error);
            }
            log.error('Error fetching suppliers:', error);
        }
    };

    const filteredSupplier = suppliers.filter(supplier =>
        supplier.supplierName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        supplier.address.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setPageCount(Math.ceil(filteredSupplier.length / rowsPerPage));
    }, [filteredSupplier]);

    useEffect(() => {
        setCurrentPage(0);
    }, [searchTerm]);

    const paginatedSuppliers = filteredSupplier.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
    );

    const handlePageChange = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
    };

    const handleChange = (e) => {
        const { type, name, checked, value } = e.target;
        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };


    const handleAddSupplier = async () => {
        const { supplierName, address, officeNo, mobileNo } = formData;

        if (!supplierName) {
            setAlertMessage('Please enter the Supplier Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            supplierNameRef.current.focus();
            return;
        }
        if (!address) {
            setAlertMessage('Please enter the Address.');
            setAlertSeverity('error');
            setAlertOpen(true);
            addressRef.current.focus();
            return;
        }
        if (!officeNo) {
            setAlertMessage('Please enter the Office No.');
            setAlertSeverity('error');
            setAlertOpen(true);
            officeNoRef.current.focus();
            return;
        }

        if (officeNo.length < 10) {
            setAlertMessage('Please enter a valid office number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            officeNoRef.current.focus();
            return;
        }

        if (mobileNo.length > 0 && mobileNo.length < 10) {
            setAlertMessage('Please enter a valid mobile number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            mobileNoRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("N");

    };

    const handleConfirmAddSupplier = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/supplier/savesupplier`, { newSupplier: formData, sysUser: user[0].User_Name });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setVisible(false);
                setFormData({
                    supplierName: '',
                    address: '',
                    email: '',
                    officeNo: '',
                    mobileNo: '',
                    status: true,
                });
                fetchSuppliers();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleUpdateSupplier = async () => {
        const { supplierName, address, officeNo, mobileNo } = updateFormData;

        if (!supplierName) {
            setAlertMessage('Please enter the Supplier Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            supplierNameRef.current.focus();
            return;
        }
        if (!address) {
            setAlertMessage('Please enter the Address.');
            setAlertSeverity('error');
            setAlertOpen(true);
            addressRef.current.focus();
            return;
        }
        if (!officeNo) {
            setAlertMessage('Please enter the Office No.');
            setAlertSeverity('error');
            setAlertOpen(true);
            officeNoRef.current.focus();
            return;
        }

        if (officeNo.length < 10) {
            setAlertMessage('Please enter a valid office number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            officeNoRef.current.focus();
            return;
        }

        if (mobileNo.length > 0 && mobileNo.length < 10) {
            setAlertMessage('Please enter a valid mobile number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            mobileNoRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("U");
    };

    const handleConfirmUpdateSupplier = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/supplier/updatesupplier`, { updatedSupplier: updateFormData, sysUser: user[0].User_Name });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setUpdateVisible(false);
                fetchSuppliers();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);

            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleEditClick = (supplier) => {
        setUpdateFormData({
            supplierId: supplier.supplierId,
            supplierName: supplier.supplierName,
            address: supplier.address,
            email: supplier.email,
            officeNo: supplier.officeNo,
            mobileNo: supplier.mobileNo,
            status: supplier.status === "Active",
        });
        setUpdateVisible(true);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                    <Toolbar>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                            Supplier Management
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            variant="outlined"
                            label="Search"
                            fullWidth
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => setVisible(true)}
                        >
                            Add New Supplier
                        </Button>
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead sx={{ bgcolor: '#424242' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>Supplier Name</TableCell>
                                <TableCell style={{ color: 'white' }}>Address</TableCell>
                                <TableCell style={{ color: 'white' }}>Email</TableCell>
                                <TableCell style={{ color: 'white' }}>Office No</TableCell>
                                <TableCell style={{ color: 'white' }}>Mobile No</TableCell>
                                <TableCell style={{ color: 'white' }}>Status</TableCell>
                                <TableCell style={{ color: 'white' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedSuppliers.map((supplier) => (
                                <TableRow key={supplier.supplierId} hover>
                                    <TableCell>{supplier.supplierName}</TableCell>
                                    <TableCell>{supplier.address}</TableCell>
                                    <TableCell>{supplier.email}</TableCell>
                                    <TableCell>{supplier.officeNo}</TableCell>
                                    <TableCell>{supplier.mobileNo}</TableCell>
                                    <TableCell>{supplier.status}</TableCell>
                                    <TableCell>
                                        <Button variant="outlined" color="primary" onClick={() => handleEditClick(supplier)}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                {suppliers.length > rowsPerPage && (
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                        style={{ marginTop: '20px' }}
                    />
                )}

                {/* Dialogs */}
                <Dialog open={visible} onClose={() => setVisible(false)}>
                    <DialogTitle>Add New Supplier</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                <TextField
                                    inputRef={supplierNameRef}
                                    name="supplierName"
                                    label="Supplier Name"
                                    value={formData.supplierName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                <TextField
                                    inputRef={addressRef}
                                    name="address"
                                    label="Address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={emailRef}
                                    name="email"
                                    label="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={officeNoRef}
                                    name="officeNo"
                                    label="Office No"
                                    value={formData.officeNo}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,10}$/.test(value)) {
                                            handleChange(e);
                                        }
                                    }}
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    inputRef={mobileNoRef}
                                    name="mobileNo"
                                    label="Mobile No"
                                    value={formData.mobileNo}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,10}$/.test(value)) {
                                            handleChange(e);
                                        }
                                    }}
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    label="Status"
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            checked={formData.status} // Replace with your state variable
                                            onChange={handleChange} // Replace with your checkbox change handler
                                            name="status" // Replace with your checkbox name
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setVisible(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleAddSupplier} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Update Item Dialog */}
                <Dialog open={updateVisible} onClose={() => setUpdateVisible(false)}>
                    <DialogTitle>Update Supplier Details</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                <TextField
                                    inputRef={supplierNameRef}
                                    name="supplierName"
                                    label="Supplier Name"
                                    value={updateFormData.supplierName}
                                    onChange={(e) => setUpdateFormData({ ...updateFormData, supplierName: e.target.value })}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                <TextField
                                    inputRef={addressRef}
                                    name="address"
                                    label="Address"
                                    value={updateFormData.address}
                                    onChange={(e) => setUpdateFormData({ ...updateFormData, address: e.target.value })}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={emailRef}
                                    name="email"
                                    label="Email"
                                    value={updateFormData.email}
                                    onChange={(e) => setUpdateFormData({ ...updateFormData, email: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={officeNoRef}
                                    name="officeNo"
                                    label="Office No"
                                    value={updateFormData.officeNo}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,10}$/.test(value)) {
                                            setUpdateFormData({ ...updateFormData, officeNo: e.target.value })
                                        }
                                    }}
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 10 }}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={mobileNoRef}
                                    name="mobileNo"
                                    label="Mobile No"
                                    value={updateFormData.mobileNo}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,10}$/.test(value)) {
                                            setUpdateFormData({ ...updateFormData, mobileNo: e.target.value })
                                        }
                                    }}
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    label="Status"
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            checked={updateFormData.status} // Replace with your state variable
                                            onChange={(e) => setUpdateFormData({ ...updateFormData, status: e.target.checked })}
                                            name="status" // Replace with your checkbox name
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setUpdateVisible(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleUpdateSupplier} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Custom Alert */}
                <CustomAlert
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    message={alertMessage}
                    severity={alertSeverity}
                />

                {/* Confirmation Alert */}
                <CustomAlert
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    message={confirmType === 'N' ? "Do you want to add this supplier?" :
                        confirmType === 'U' ? "Do you want to update this supplier?" : ""}
                    severity="warning"
                    onConfirm={confirmType === 'N' ? handleConfirmAddSupplier :
                        confirmType === 'U' ? handleConfirmUpdateSupplier : null}
                    isConfirmation
                />

            </Container>
        </ThemeProvider>
    );
};

export default Suppier;
