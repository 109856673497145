import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Divider,
  Grid,
} from '@mui/material';
import axios from 'axios';
import ReturnWasteDialog from './ReturnWasteDialog';
import config from '../../services/config';
import log from 'loglevel';

const ItemDetailsDialog = ({ open, onClose, item, branchId, user, isBranchStock }) => {
  const [returnWasteDialogOpen, setReturnWasteDialogOpen] = useState(false);
  const [selectedQty, setSelectedQty] = useState(0);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [selectedBranchStockId, setSelectedBranchStockId] = useState(null);
  const [selectedBuyingPrice, setSelectedBuyingPrice] = useState(null);
  const [selectedSellingPrice, setSelectedSellingPrice] = useState(null);
  const [pricingDetails, setPricingDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  log.setLevel('info');

  // Memoized function to fetch pricing details
  const fetchPricingDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.API_BASE_URL}/stock/getbranchitembatch`, {
        params: { itemCode: item.itemCode, branchId: branchId },
      });
      setPricingDetails(response.data);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error fetching pricing details:', error);
      }
      log.error('Error fetching pricing details:', error);
    } finally {
      setLoading(false);
    }
  }, [item.itemCode, branchId]);

  // Fetch pricing details when the dialog opens
  useEffect(() => {
    if (open) {
      fetchPricingDetails();
    }
  }, [open, fetchPricingDetails]);

  const handleReturnWasteOpen = (qty, itemBatchId, branchStockId, buyingPrice, sellingPrice) => {
    setSelectedQty(qty);
    setSelectedBatchId(itemBatchId);
    setSelectedBranchStockId(branchStockId);
    setSelectedBuyingPrice(buyingPrice);
    setSelectedSellingPrice(sellingPrice);
    setReturnWasteDialogOpen(true);
  };

  const handleReturnWasteClose = () => {
    setReturnWasteDialogOpen(false);
  };

  const handleReturnWasteSave = async () => {
    await fetchPricingDetails(); // Refresh pricing details after saving
    setReturnWasteDialogOpen(false);
  };

  // Helper function to format numbers
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>Item Details</DialogTitle>

      <DialogContent dividers>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Item Code:</strong> {item.itemCode}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Item Name:</strong> {item.itemName}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Company:</strong> {item.company}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Item Type:</strong> {item.itemType}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Unit:</strong> {item.unit}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Available Qty:</strong> {item.availableQty}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Reminding Qty:</strong> {item.remindingQty}
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                <strong>Other Details:</strong> {item.otherDetails}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" sx={{ mb: 2 }}>
          Item Batches
        </Typography>
        <Table>
          <TableHead sx={{ bgcolor: 'grey.100' }}>
            <TableRow>
              <TableCell><strong>Quantity</strong></TableCell>
              {user[0].User_Type !== "User" && (
                <TableCell align="right"><strong>Buying Price (Rs.)</strong></TableCell>
              )}
              <TableCell align="right"><strong>Selling Price (Rs.)</strong></TableCell>
              {user[0].User_Type !== "User" && isBranchStock && <TableCell><strong>Action</strong></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={isBranchStock ? 4 : 3} align="center">
                  <Typography>Loading...</Typography>
                </TableCell>
              </TableRow>
            ) : pricingDetails.length > 0 ? (
              pricingDetails.map((detail, index) => (
                <TableRow key={index}>
                  <TableCell>{detail.qty}</TableCell>
                  {user[0].User_Type !== "User" && (
                    <TableCell align="right">{formatNumber(detail.buyingPrice)}</TableCell>
                  )}
                  <TableCell align="right">{formatNumber(detail.sellingPrice)}</TableCell>
                  {user[0].User_Type !== "User" && isBranchStock && (
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleReturnWasteOpen(detail.qty, detail.itemBatchId, detail.branchStockId, detail.buyingPrice, detail.sellingPrice)}
                      >
                        Return/Waste
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={isBranchStock ? 4 : 3} align="center">
                  <Typography>No pricing details found.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>

      {/* Return/Waste Dialog */}
      <ReturnWasteDialog
        open={returnWasteDialogOpen}
        onClose={handleReturnWasteClose}
        branchStockId={selectedBranchStockId}
        itemBatchId={selectedBatchId}
        itemCode={item.itemCode}
        branchId={branchId}
        availableQty={selectedQty}
        buyingPrice={selectedBuyingPrice}
        sellingPrice={selectedSellingPrice}
        user={user}
        onSave={handleReturnWasteSave} // Handle the save action
      />
    </Dialog>
  );
};

export default ItemDetailsDialog;
