import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid } from '@mui/material';
import axios from 'axios'; // Import axios or use fetch
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const UserChangePassword = ({ open, onClose, userName, sysUser }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    log.setLevel('info');

    // State for alerts
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);
    const [successUpdate, setSuccessUpdate] = useState(false);

    // Create refs for input fields
    const oldPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmNewPasswordRef = useRef(null);

    const resetFields = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    };

    useEffect(() => {
        if (open) {
            resetFields(); // Clear fields when dialog opens
        }
    }, [open]);

    const handleUpdatePassword = async () => {

        if (!oldPassword) {
            setAlertMessage('Please enter the Old Password.');
            setAlertSeverity('error');
            setAlertOpen(true);
            oldPasswordRef.current.focus();
            return;
        }
        if (!newPassword) {
            setAlertMessage('Please enter the New Password.');
            setAlertSeverity('error');
            setAlertOpen(true);
            newPasswordRef.current.focus();
            return;
        }
        if (!confirmNewPassword) {
            setAlertMessage('Please enter the Confirm Password.');
            setAlertSeverity('error');
            setAlertOpen(true);
            confirmNewPasswordRef.current.focus();
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setAlertMessage('The new password and new confirmation password do not match.');
            setAlertSeverity('error');
            setAlertOpen(true);
            confirmNewPasswordRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("U");
    };

    const handleConfirmUpdatePassword = async () => {

        try {
            // Make an API call to update the password
            const response = await axios.post(`${config.API_BASE_URL}/user/changepassword`, {
                userName,
                oldPassword,
                newPassword,
                sysUser,
            });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);
            setSuccessUpdate(response.data.success);

        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);

            if (process.env.NODE_ENV === 'development') {
                console.error('Error updating password:', error);
            }
            log.error('Error updating password:', error);
        } finally {
            setConfirmOpen(false);
        }

    };

    const handleAlertClose = () => {
        setAlertOpen(false);
        if (successUpdate) {
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <TextField
                            label="User Name"
                            value={userName}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Old Password"
                            type="password"
                            value={oldPassword.trim()}
                            onChange={(e) => setOldPassword(e.target.value)}
                            fullWidth
                            required
                            inputRef={oldPasswordRef}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="New Password"
                            type="password"
                            value={newPassword.trim()}
                            onChange={(e) => setNewPassword(e.target.value)}
                            fullWidth
                            required
                            inputRef={newPasswordRef}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Confirm New Password"
                            type="password"
                            value={confirmNewPassword.trim()}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            fullWidth
                            required
                            inputRef={confirmNewPasswordRef}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button onClick={handleUpdatePassword} color="primary">Update</Button>
            </DialogActions>

            {/* Custom Alert */}
            <CustomAlert
                open={alertOpen}
                onClose={handleAlertClose}
                message={alertMessage}
                severity={alertSeverity}
            />

            {/* Confirmation Alert */}
            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'U' ? "Do you want to change your password?" : ""}
                severity="warning"
                onConfirm={confirmType === 'U' ? handleConfirmUpdatePassword : null}
                isConfirmation
            />

        </Dialog>
    );
};

export default UserChangePassword;
