import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    Grid,
    TextField,
    Button,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    AppBar,
    Toolbar,
    TableContainer,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';
import GrnSearch from './GrnSearch';

const Grn = ({ user }) => {
    const [suppliers, setSuppliers] = useState([]);
    const [items, setItems] = useState([]);
    const [grnItems, setGrnItems] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemDetails, setItemDetails] = useState({
        qty: 0,
        buyingPrice: (0.00).toFixed(2),
        discount: (0.00).toFixed(2),
        sellingPrice: (0.00).toFixed(2),
        subtotal: (0.00).toFixed(2),
    });
    const [grandTotal, setGrandTotal] = useState((0.00).toFixed(2));
    log.setLevel('info');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    const [searchDialogOpen, setSearchDialogOpen] = useState(false);

    const supplierRef = useRef(null);
    const itemRef = useRef(null);
    const qtyRef = useRef(null);
    const buyingPriceRef = useRef(null);
    const discountRef = useRef(null);
    const sellingPriceRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const suppliersResponse = await axios.get(`${config.API_BASE_URL}/supplier/getsuppliersforcombo`);
            setSuppliers(suppliersResponse.data);

            const itemsResponse = await axios.get(`${config.API_BASE_URL}/item/getallitems`);
            setItems(itemsResponse.data);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const subtotal = (itemDetails.qty * itemDetails.buyingPrice).toFixed(2);
        setItemDetails(prevDetails => ({
            ...prevDetails,
            subtotal,
        }));
    }, [itemDetails.qty, itemDetails.buyingPrice]);

    useEffect(() => {
        const total = grnItems.reduce((acc, item) => acc + parseFloat(item.subtotal), 0).toFixed(2);
        setGrandTotal(total);
    }, [grnItems]);

    useEffect(() => {
        if (supplierRef.current) {
            supplierRef.current.focus();
        }
    }, []);

    const handleItemSelect = (event, value) => {
        if (value) {
            setSelectedItem(value);
            setItemDetails(prev => ({
                ...prev,
                itemName: value.itemName,
                itemCode: value.itemCode,
            }));
        } else {
            setSelectedItem(null);
        }
    };

    const handleSupplierSelect = (event, value) => {
        if (value) {
            setSelectedSupplier(value);
        } else {
            setSelectedSupplier(null);
        }
    };

    const validateInteger = (value) => {
        return /^\d*$/.test(value);
    };

    const validateDecimal = (value) => {
        return /^\d*\.?\d*$/.test(value);
    };

    const handleInputChange = (field, value) => {
        if (field === 'qty' && validateInteger(value)) {
            setItemDetails(prev => ({ ...prev, qty: value }));
        } else if ((field === 'buyingPrice' || field === 'discount' || field === 'sellingPrice') && validateDecimal(value)) {
            setItemDetails(prev => ({ ...prev, [field]: value }));
        }
    };

    const addItemToTable = () => {

        if (!selectedItem) {
            setAlertMessage('Please select an Item.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemRef.current.focus();
            return;
        }
        if (itemDetails.qty <= 0) {
            setAlertMessage('Please enter a quantity greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            qtyRef.current.focus();
            return;
        }
        if (parseFloat(itemDetails.buyingPrice) <= 0) {
            setAlertMessage('Please enter a buying price greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            buyingPriceRef.current.focus();
            return;
        }
        if (parseFloat(itemDetails.sellingPrice) <= parseFloat(itemDetails.buyingPrice)) {
            setAlertMessage('Please enter a selling price greater than the buying price.');
            setAlertSeverity('error');
            setAlertOpen(true);
            sellingPriceRef.current.focus();
            return;
        }
        if (parseFloat(itemDetails.discount) > (parseFloat(itemDetails.sellingPrice) - parseFloat(itemDetails.buyingPrice))) {
            setAlertMessage('Please ensure the discount is less than the difference between the selling price and buying price.');
            setAlertSeverity('error');
            setAlertOpen(true);
            discountRef.current.focus();
            return;
        }

        setGrnItems(prevItems => [
            ...prevItems,
            {
                ...itemDetails,
                qty: itemDetails.qty,
                buyingPrice: parseFloat(itemDetails.buyingPrice).toFixed(2),
                sellingPrice: parseFloat(itemDetails.sellingPrice).toFixed(2),
                discount: parseFloat(itemDetails.discount).toFixed(2),
                subtotal: parseFloat(itemDetails.qty * itemDetails.buyingPrice).toFixed(2),
            },
        ]);
        clearFields();

        if (itemRef.current) {
            itemRef.current.focus();
        }
    };

    const clearFields = () => {
        setItemDetails({
            qty: 0,
            buyingPrice: (0.00).toFixed(2),
            discount: (0.00).toFixed(2),
            sellingPrice: (0.00).toFixed(2),
            subtotal: (0.00).toFixed(2),
        });
        setSelectedItem(null);
    };

    const clearAll = () => {
        setConfirmOpen(true);
        setConfirmType("C");
    };
    const confirmClearAll = () => {
        setItemDetails({
            qty: 0,
            buyingPrice: (0.00).toFixed(2),
            discount: (0.00).toFixed(2),
            sellingPrice: (0.00).toFixed(2),
            subtotal: (0.00).toFixed(2),
        });
        setSelectedItem(null);
        setSelectedSupplier(null);
        setGrnItems([]);
        setConfirmOpen(false);
    };

    const removeItem = (index) => {
        setGrnItems(grnItems.filter((_, i) => i !== index));
    };

    const saveGRN = async () => {

        if (!selectedSupplier) {
            setAlertMessage('Please select a Supplier.');
            setAlertSeverity('error');
            setAlertOpen(true);
            supplierRef.current.focus();
            return;
        }

        if (grnItems.length === 0) {
            setAlertMessage('Please add at least one item to the GRN before saving.');
            setAlertSeverity('error');
            setAlertOpen(true);
            itemRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("S");
    };

    const saveConfirmGRN = async () => {

        try {

            const grnData = {
                supplier: selectedSupplier,
                items: grnItems,
                grandTotal,
            };

            const response = await axios.post(`${config.API_BASE_URL}/grn/savegrn`, { grnData: grnData, sysUser: user[0].User_Name, loginId: user[0].Login_Id });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                confirmClearAll();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleFocus = (ref) => {
        if (ref.current) {
            ref.current.focus();
            ref.current.select(); // Select text on focus
        }
    };

    const handleSearchDialogOpen = () => {
        setSearchDialogOpen(true);
    };

    const handleSearchDialogClose = () => {
        setSearchDialogOpen(false);
    };

    return (
        <Container>
            <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                <Toolbar>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        Goods Received Note
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={suppliers}
                        getOptionLabel={(supplier) => supplier.supplierName}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Supplier"
                                variant="outlined"
                                fullWidth
                                required
                                inputRef={supplierRef}
                            />
                        )}
                        value={selectedSupplier}
                        onChange={handleSupplierSelect}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={items}
                        getOptionLabel={(item) => `${item.itemCode} - ${item.itemName}`}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Item Code / Name"
                                variant="outlined"
                                fullWidth
                                required
                                inputRef={itemRef}
                            />
                        )}
                        value={selectedItem}
                        onChange={handleItemSelect}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        inputRef={qtyRef}
                        label="Quantity"
                        type="text"
                        value={itemDetails.qty}
                        onChange={(e) => handleInputChange('qty', e.target.value)}
                        onBlur={() => {
                            if (itemDetails.qty === '') {
                                setItemDetails(prev => ({ ...prev, qty: 0 }));
                            }
                        }}
                        fullWidth
                        required
                        onFocus={() => handleFocus(qtyRef)}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        inputRef={buyingPriceRef}
                        label="Buying Price"
                        type="text"
                        value={itemDetails.buyingPrice}
                        onChange={(e) => handleInputChange('buyingPrice', e.target.value)}
                        onBlur={() => {
                            if (!itemDetails.buyingPrice) {
                                setItemDetails(prev => ({ ...prev, buyingPrice: (0.00).toFixed(2) }));
                            }
                        }}
                        fullWidth
                        required
                        onFocus={() => handleFocus(buyingPriceRef)}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Subtotal"
                        type="number"
                        value={itemDetails.subtotal}
                        fullWidth
                        disabled
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        inputRef={discountRef}
                        label="Invoice Discount"
                        type="text"
                        value={itemDetails.discount}
                        onChange={(e) => handleInputChange('discount', e.target.value)}
                        onBlur={() => {
                            if (!itemDetails.discount) {
                                setItemDetails(prev => ({ ...prev, discount: (0.00).toFixed(2) }));
                            }
                        }}
                        fullWidth
                        onFocus={() => handleFocus(discountRef)}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        inputRef={sellingPriceRef}
                        label="Selling Price"
                        type="text"
                        value={itemDetails.sellingPrice}
                        onChange={(e) => handleInputChange('sellingPrice', e.target.value)}
                        onBlur={() => {
                            if (!itemDetails.sellingPrice) {
                                setItemDetails(prev => ({ ...prev, sellingPrice: (0.00).toFixed(2) }));
                            }
                        }}
                        fullWidth
                        required
                        onFocus={() => handleFocus(sellingPriceRef)}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Button variant="contained" color="primary" onClick={addItemToTable}>
                        Add Item
                    </Button>
                </Grid>
            </Grid>

            <Paper sx={{ mt: 3 }}>
                <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Code</TableCell>
                                <TableCell>Item Name</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Buying Price</TableCell>
                                <TableCell>Selling Price</TableCell>
                                <TableCell>Discount</TableCell>
                                <TableCell>Subtotal</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {grnItems.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                grnItems.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.itemCode}</TableCell>
                                        <TableCell>{item.itemName}</TableCell>
                                        <TableCell>{item.qty}</TableCell>
                                        <TableCell>{parseFloat(item.buyingPrice).toFixed(2)}</TableCell>
                                        <TableCell>{parseFloat(item.sellingPrice).toFixed(2)}</TableCell>
                                        <TableCell>{parseFloat(item.discount).toFixed(2)}</TableCell>
                                        <TableCell>{parseFloat(item.subtotal).toFixed(2)}</TableCell>
                                        <TableCell>
                                            <Button color="secondary" onClick={() => removeItem(index)}>
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Typography variant="h6" sx={{ mt: 2 }}>
                Grand Total: {grandTotal}
            </Typography>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outlined" color="primary" onClick={clearAll}>
                    Clear
                </Button>
                <Button variant="contained" color="primary" onClick={saveGRN}>
                    Save GRN
                </Button>
                <Button variant="outlined" color="primary" onClick={handleSearchDialogOpen}>
                    Search GRN
                </Button>
            </Box>

            {/* GrnSearch component */}
            <GrnSearch
                open={searchDialogOpen}
                onClose={handleSearchDialogClose}
            />

            {/* Custom Alert */}
            <CustomAlert
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                message={alertMessage}
                severity={alertSeverity}
            />

            {/* Confirmation Alert */}
            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'S' ? "Do you want to save the GRN?" :
                    confirmType === 'C' ? "Do you want to clear the GRN?" : ""}
                severity="warning"
                onConfirm={confirmType === 'S' ? saveConfirmGRN :
                    confirmType === 'C' ? confirmClearAll : null}
                isConfirmation
            />

        </Container>
    );
};

export default Grn;
