import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Grid, Typography, TextField, MenuItem, Select, InputLabel, FormControl, Paper, AppBar, Toolbar, Container } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';
import axios from 'axios';
import { printSalesReport } from '../utils/PrintUtils';

function Reports({ branchId }) {
    const [selectedReport, setSelectedReport] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [year, setYear] = useState('');
    const [customerSupplierId, setCustomerSupplierId] = useState('');
    const [branches, setBranches] = useState([]);
    const [columns, setColumns] = useState([]);
    const [records, setRecords] = useState([]);
    const [totalAmount, setTotalAmount] = useState((0).toFixed(2));

    log.setLevel('info');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    const reportRef = useRef(null);
    const branchRef = useRef(null);
    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);
    const iframeRef = useRef();

    const fetchData = useCallback(async () => {
        try {
            const branchesResponse = await axios.get(`${config.API_BASE_URL}/branch/getbranches`);
            const branchesData = branchesResponse.data;

            const allBranchesItem = {
                branchId: '0',
                branchName: 'All Branches'
            };

            setBranches([allBranchesItem, ...branchesData]);

        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching data:', error);
            }
            log.error('Error fetching data:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const reportOptions = [
        // { label: 'Daily Sale & Profit Detail Report', value: 'dailySaleProfitDetail' },
        // { label: 'Daily Sale & Profit Summary Report', value: 'dailySaleProfitSummary' },
        { label: 'Sales Report', value: 'sales' },
        // { label: 'Return Report', value: 'return' },
        // { label: 'Sales Profit Report', value: 'salesProfit' },
        // { label: 'Daily Cash Details Report', value: 'dailyCashDetails' },
        // { label: 'Monthly Sale & Profit Report', value: 'monthlySaleProfit' },
        // { label: 'Item wise Sales Report', value: 'itemWiseSales' },
        // { label: 'Cash Details', value: 'cash' },
        // { label: 'Purchase Report', value: 'purchase' },
        // { label: 'Company Return/Damage Item', value: 'returnDamage' },
        // { label: 'Customer wise Outstanding Balance', value: 'customerWiseOutstanding' },
        // { label: 'Extra Income & Expense', value: 'extraIncomeExpense' },
        // { label: 'Price Update History', value: 'priceUpdateHistory' },
    ];

    const handleBranchSelect = (event) => {
        const branchId = event.target.value;
        const selected = branches.find(branch => branch.branchId === branchId);
        setSelectedBranch(selected || null);
    };

    const searchReport = async () => {
        try {

            if (!selectedReport) {
                setAlertMessage('Please select a Report.');
                setAlertSeverity('error');
                setAlertOpen(true);
                reportRef.current.focus();
                return;
            }
            if (!selectedBranch) {
                setAlertMessage('Please select a Branch.');
                setAlertSeverity('error');
                setAlertOpen(true);
                branchRef.current.focus();
                return;
            }
            if (!fromDate) {
                setAlertMessage('Please select a From Date.');
                setAlertSeverity('error');
                setAlertOpen(true);
                fromDateRef.current.focus();
                return;
            }
            if (!toDate) {
                setAlertMessage('Please select a To Date.');
                setAlertSeverity('error');
                setAlertOpen(true);
                toDateRef.current.focus();
                return;
            }

            const fromDateObj = new Date(fromDate);
            const toDateObj = new Date(toDate);
            const currentDate = new Date();

            if (fromDateObj > toDateObj) {
                setAlertMessage('From Date cannot be greater than To Date.');
                setAlertSeverity('error');
                setAlertOpen(true);
                fromDateRef.current.focus();
                return;
            }
            if (toDateObj > currentDate) {
                setAlertMessage('To Date cannot be greater than the current system date.');
                setAlertSeverity('error');
                setAlertOpen(true);
                toDateRef.current.focus();
                return;
            }

            const searchData = {
                report: selectedReport,
                branchId: selectedBranch.branchId,
                fromDate: fromDate,
                toDate: toDate,
                year: year,
                customerSupplierId: customerSupplierId,
            };

            // console.log("report " + searchData.report);
            // console.log("branchId " + searchData.branchId);
            // console.log("fromDate " + searchData.fromDate);
            // console.log("toDate " + searchData.toDate);
            // console.log("year " + searchData.year);
            // console.log("customerSupplierId " + searchData.customerSupplierId);

            setReportColumns();

            const response = await axios.get(`${config.API_BASE_URL}/report/getreports`, { params: { searchData } });

            const dataWithId = response.data.map((row, index) => ({
                ...row,
                id: row.invoiceNo || index,
            }));

            setRecords(dataWithId);
            setTotalAmount(dataWithId.reduce((sum, row) => {
                const total = row.total ? Number(row.total.toString().replace(/,/g, '')) : 0;
                return sum + total;
            }, 0));

        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const setReportColumns = () => {

        let reportColumns;

        if (selectedReport === "sales") {
            reportColumns = [
                { field: 'date', headerName: 'Date' },
                { field: 'invoiceNo', headerName: 'Invoice No' },
                { field: 'customer', headerName: 'Customer' },
                { field: 'total', headerName: 'Total (Rs.)', type: 'number', align: 'right' },
            ];
        }

        setColumns(reportColumns);
    }

    const printReport = async () => {

        if (records.length === 0 || !records) {
            setAlertMessage('No record(s) found.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        setConfirmOpen(true);
        setConfirmType("P");
    };

    const printConfirmReport = async () => {
        try {
            printSalesReport(iframeRef, branchId, records, columns, selectedBranch.branchName, fromDate, toDate);

        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your internet connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    return (
        <Container>
            <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                <Toolbar>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        Reports
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper elevation={3} sx={{ padding: 3, mb: 4 }}>
                <Grid container spacing={3} alignItems="center">
                    {/* Report Combo */}
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth>
                            <InputLabel>Report</InputLabel>
                            <Select
                                value={selectedReport}
                                label="Report"
                                onChange={(e) => setSelectedReport(e.target.value)}
                                inputRef={reportRef}
                            >
                                {reportOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Branch Combo */}
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel>Branch</InputLabel>
                            <Select
                                value={selectedBranch ? selectedBranch.branchId : ''}
                                onChange={handleBranchSelect}
                                inputRef={branchRef}
                            >
                                {branches.map((branch) => (
                                    <MenuItem key={branch.branchId} value={branch.branchId}>
                                        {branch.branchName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* From Date */}
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            label="From Date"
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            fullWidth
                            inputRef={fromDateRef}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    {/* To Date */}
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            label="To Date"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            fullWidth
                            inputRef={toDateRef}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    {/* Search Button */}
                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={searchReport}
                            fullWidth
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {/* Data Grid to Display Filtered Data */}
            <Paper
                elevation={3}
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    minHeight: 400
                }}
            >
                <DataGrid
                    rows={records}
                    columns={columns.map((col) => ({
                        ...col,
                        flex: 1, // Responsive column width
                        headerClassName: 'header-style',
                    }))}
                    autoHeight // Adjusts height based on content
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => row.invoiceNo || row.id}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#e0e0e0',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none', // Optional styling for borders
                        },
                        '@media (max-width: 600px)': { // Adjustments for small screens
                            '& .MuiDataGrid-columnHeaders': {
                                fontSize: '0.8rem',
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: '0.75rem',
                            },
                        },
                    }}
                />

                <Typography variant="h6" sx={{ mt: 5 }}>
                    {selectedReport === "sales" && (
                        <>Total Sales (Rs.): {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalAmount)}</>
                    )}
                </Typography>

                <Typography variant="h6" sx={{ mt: 5 }}>
                    <Grid item xs={12} sm={6} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={printReport}
                            fullWidth
                        >
                            Print Report
                        </Button>
                        <iframe
                            ref={iframeRef}
                            style={{ display: 'none', width: '0', height: '0' }}
                            title="print-frame"
                        ></iframe>
                    </Grid>
                </Typography>
            </Paper>

            <CustomAlert
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                message={alertMessage}
                severity={alertSeverity}
            />

            {/* Confirmation Alert */}
            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'P' ? "Do you want to print this report?" : ""}
                severity="warning"
                onConfirm={confirmType === 'P' ? printConfirmReport : null}
                isConfirmation
            />

        </Container >
    );
}

export default Reports;
