import React from 'react';
import './BillPrint.css';

const BillPrint = React.forwardRef(({ invoiceData, items, isCopy }, ref) => {

    if (!invoiceData || !items) {
        return <div>No invoice data available.</div>;
    }

    // Render the BillPrint component
    return (
        <div ref={ref} className="bill-print">
            {/* Header Section */}
            <div className="header">
                <img alt="Company Logo" className="logo" src="/Logo_Small.png" />
                {isCopy && <div className="copy-label">COPY</div>}
                <div className="address">{invoiceData.branchAddress}</div>
                <div className="contact">Phone: {invoiceData.branchContactNo}</div>
            </div>

            {/* Invoice Information */}
            <div className="invoice-info">
                <hr />
                <div className="info-row">
                    <div>Invoice #: {invoiceData.invoiceNo}</div>
                    <div>Date: {new Date(invoiceData.date).toLocaleDateString()}</div>
                    <div>User: {invoiceData.userName}</div>
                </div>
                <div>Customer: {invoiceData.customerName}</div>
                <hr />
            </div>

            {/* Item Details Table */}
            <table className="item-table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                        <th>Unit Price</th>
                        <th>Qty</th>
                        <th>Total</th>
                        <th>Discount</th>
                        <th>Sub Total</th>
                    </tr>
                </thead>
                <tbody>
                    {items.length > 0 ? (
                        items.map((item, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td colSpan={7} style={{ textAlign: 'left' }}>
                                        {item.itemName}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{item.itemCode}</td>
                                    <td style={{ textAlign: 'center' }}>{item.unit}</td>
                                    <td style={{ textAlign: 'right' }}>{item.sellingPrice.toFixed(2)}</td>
                                    <td style={{ textAlign: 'center' }}>{item.qty}</td>
                                    <td style={{ textAlign: 'right' }}>{(item.total).toFixed(2)}</td>
                                    <td style={{ textAlign: 'right' }}>{item.itemDiscount.toFixed(2)}</td>
                                    <td style={{ textAlign: 'right' }}>{(item.subTotal).toFixed(2)}</td>
                                </tr>
                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7} style={{ textAlign: 'center' }}>
                                No items available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <hr />

            {/* Summary Section */}
            <div className="summary">
                {invoiceData.subTotal !== invoiceData.grandTotal && (
                    <div className="summary-item">
                        <div className="label">Subtotal:</div>
                        <div>{invoiceData.subTotal.toFixed(2)}</div>
                    </div>
                )}
                {invoiceData.totalDiscount > 0 && (
                    <div className="summary-item">
                        <div className="label">Discount:</div>
                        <div>{invoiceData.totalDiscount.toFixed(2)}</div>
                    </div>
                )}
                {invoiceData.excessFunds > 0 && (
                    <div className="summary-item">
                        <div className="label">Excess Funds:</div>
                        <div>{invoiceData.excessFunds.toFixed(2)}</div>
                    </div>
                )}
                {invoiceData.grandTotal > 0 && (
                    <div className="summary-item">
                        <div className="label">Grand Total:</div>
                        <div>{invoiceData.grandTotal.toFixed(2)}</div>
                    </div>
                )}
                {invoiceData.creditAmount > 0 && (
                    <div className="summary-item">
                        <div className="label">Credit Amount:</div>
                        <div>{invoiceData.creditAmount.toFixed(2)}</div>
                    </div>
                )}
                {invoiceData.cashAmount > 0 && (
                    <div className="summary-item">
                        <div className="label">Cash Amount:</div>
                        <div>{invoiceData.cashAmount.toFixed(2)}</div>
                    </div>
                )}
                {invoiceData.cashReceived > 0 && (
                    <div className="summary-item">
                        <div className="label">Cash Received:</div>
                        <div>{invoiceData.cashReceived.toFixed(2)}</div>
                    </div>
                )}
                {invoiceData.cashReceived > 0 && (
                    <div className="summary-item">
                        <div className="label">Balance:</div>
                        <div>{invoiceData.balance.toFixed(2)}</div>
                    </div>
                )}
                <hr />
            </div>

            {/* Footer Section */}
            <div className="footer">
                <div>Software by KD2 Technology - 0714589293 / 0773075020</div>
                <div>Copyright &copy; 2020-{new Date().getFullYear()} Lap Tech. All Rights Reserved.</div>
            </div>
        </div>
    );
});

export default BillPrint;
