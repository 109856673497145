import React, { useEffect, useState, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Checkbox,
    CircularProgress,
    TableContainer,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import log from 'loglevel';
import { printInvoice } from '../utils/PrintUtils';
import CustomAlert from '../common/CustomAlert';

// Header Component
const InvoiceDetailsHeader = ({ invoiceData }) => (
    <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={4}>
            <Typography variant="body1"><strong>Invoice No:</strong> {invoiceData.invoiceNo}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
            <Typography variant="body1"><strong>Customer:</strong> {invoiceData.customerName}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
            <Typography variant="body1"><strong>Date:</strong> {invoiceData.date}</Typography>
        </Grid>
    </Grid>
);

// Invoice Items Table Component
const InvoiceItemsTable = ({ invoiceItems }) => (
    <Paper sx={{ mt: 2, borderRadius: 2 }} elevation={2}>
        <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {['Item Code', 'Item Name', 'Unit', 'Qty', 'Selling Price', 'Total', 'Discount', 'Subtotal'].map(header => (
                            <TableCell key={header}><strong>{header}</strong></TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoiceItems.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={8} align="center">
                                <Typography variant="body2" color="textSecondary">No items available</Typography>
                            </TableCell>
                        </TableRow>
                    ) : (
                        invoiceItems.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.itemCode}</TableCell>
                                <TableCell>{item.itemName}</TableCell>
                                <TableCell>{item.unit}</TableCell>
                                <TableCell align="center">{item.qty}</TableCell>
                                <TableCell align="right">{parseFloat(item.sellingPrice).toFixed(2)}</TableCell>
                                <TableCell align="right">{parseFloat(item.total).toFixed(2)}</TableCell>
                                <TableCell align="right">{parseFloat(item.itemDiscount).toFixed(2)}</TableCell>
                                <TableCell align="right">{parseFloat(item.subTotal).toFixed(2)}</TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
);

// Label Component
const Label = ({ text }) => (
    <Typography variant="body1" sx={{ marginRight: '8px' }}>
        <strong>{text}:</strong>
    </Typography>
);

// Value Component
const Value = ({ value }) => (
    <Typography variant="body1" align="right" sx={{ flexGrow: 1, textAlign: 'right' }}>
        {parseFloat(value).toFixed(2)}
    </Typography>
);

// Redesigned Invoice Summary Component
const InvoiceSummary = ({ invoiceData }) => {
    const summaryItems = [
        { label: 'Sub Total', value: invoiceData.subTotal },
        { label: 'Discount', value: invoiceData.totalDiscount },
        { label: 'Excess Funds', value: invoiceData.excessFunds },
        { label: 'Credit Amount', value: invoiceData.creditAmount },
        { label: 'Cash Amount', value: invoiceData.cashAmount },
        { label: 'Grand Total', value: invoiceData.grandTotal },
    ];

    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} container alignItems="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>
                    Payment Method:
                </Typography>
                <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                        <Checkbox checked={invoiceData.cashAmount > 0} disabled />
                        <Typography variant="body2" display="inline">Cash</Typography>
                    </Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox checked={invoiceData.creditAmount > 0} disabled />
                        <Typography variant="body2" display="inline">Credit</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {summaryItems.map((item, index) => (
                <Grid item xs={12} sm={6} key={index} container alignItems="center">
                    <Label text={item.label} />
                    <Value value={item.value} />
                </Grid>
            ))}
            <Grid item xs={12} sm={6} container alignItems="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Credit Period:
                </Typography>
                <Typography variant="body1" sx={{ ml: 1 }}>
                    {invoiceData.creditPeriod} days
                </Typography>
            </Grid>
        </Grid>
    );
};

// Main Invoice Detail Component
const InvoiceDetail = ({ open, onClose, invoice, branchId }) => {
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const iframeRef = useRef();
    log.setLevel('info');

    useEffect(() => {
        const fetchInvoiceItems = async () => {
            if (invoice && invoice.invoiceNo) {
                try {
                    const response = await axios.get(`${config.API_BASE_URL}/invoice/getinvoicedata`, { params: { invoiceNo: invoice.invoiceNo, branchId } });
                    setInvoiceData(response.data.invoice[0]);
                    setInvoiceItems(response.data.items);
                } catch (error) {
                    setError('Error fetching Invoice details. Please try again later.');
                    log.error('Error fetching Invoice details:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchInvoiceItems();
    }, [invoice, branchId]);

    const printInvoice = () => {
        setConfirmOpen(true);
    };

    const printConfirmInvoice = () => {
        printInvoice(iframeRef, invoiceData, invoiceItems, true);
        setConfirmOpen(false);
    };

    if (!invoice) return null;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                <Typography variant="h6">Invoice Details</Typography>
            </DialogTitle>
            <DialogContent sx={{ mt: 3 }}>
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Typography variant="body1" color="error">{error}</Typography>
                ) : (
                    <>
                        <InvoiceDetailsHeader invoiceData={invoiceData} />
                        <InvoiceItemsTable invoiceItems={invoiceItems} />
                        <InvoiceSummary invoiceData={invoiceData} />
                        <Button variant="contained" sx={{ mt: 2 }} onClick={printInvoice}>
                            Reprint Invoice
                        </Button>
                        <iframe
                            ref={iframeRef}
                            style={{ display: 'none', width: '0', height: '0' }}
                            title="print-frame"
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>

            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={"Do you want to re-print the Invoice?"}
                severity="warning"
                onConfirm={printConfirmInvoice}
                isConfirmation
            />
        </Dialog>
    );
};

export default InvoiceDetail;
